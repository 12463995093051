.about .section-intro {
  margin-bottom: 2rem;
}

.about-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
}

@media (min-width: 1200px) {
  .about-container {
    grid-template-columns: 40% 50%;
    gap: 10%;
  }
}

.about-image {
  overflow: hidden;
  border: 4px solid var(--primary-color);
  margin: 0 auto;
  height: fit-content;
}

/* @media (min-width: 1200px) {
  .about-image {
    max-width: 100%;
  }
} */

.about-image img {
  width: 100%;
  transition: transform 0.3s ease;
}

.about-image:hover img {
  transform: scale(1.1);
}

.about-content .cards {
  margin-bottom: 2rem;
}

.card-item .card-title {
  font-size: 1.25rem;
}

.cards .card-icon {
  color: var(--primary-color);
  font-size: 1.75rem;
  margin-bottom: 1rem;
}
