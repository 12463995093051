.progress-container {
  margin-top: 3rem;
}

.progress-item {
  width: 100%;
  margin: 20px 0;
}

.progress-item h3,
.progress-item .h3 {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}

.progress {
  height: 3px;
  background-color: rgba(111, 111, 111, 0.2);
  display: flex;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.progress-bar {
  background-color: var(--primary-color);
  font-size: 12px;
  line-height: 1;
  position: relative;
  border-radius: 2px;
  display: flex;
  white-space: nowrap;
  transition: width 0.8s linear;
}

.progress-bar span {
  position: absolute;
  top: -40px;
  right: 0;
  font-weight: 500;
  color: var(--primary-color);
  line-height: 1;
  background-color: #000000;
  padding: 0.4rem 0.5rem;
  border-radius: 3px;
}

.progress-bar span::after {
  position: absolute;
  left: 50%;
  bottom: -5px;
  margin-left: -5px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #000000;
  content: "";
}
