footer {
  background: var(--primary-color);
  padding: 6rem 0 1rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
  -webkit-clip-path: polygon(0 12vh, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 12vh, 100% 0, 100% 100%, 0 100%);
  color: #232347;
}

.footer-heading {
  font-size: 2rem;
  /* color: var(--secondary-color); */
  margin-bottom: 2rem;
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto 3rem;
  font-weight: 500;
  list-style: none;
}

.footer-menu > li > a {
  text-transform: capitalize;
  color: #232347;
}

.footer-socials {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.footer-socials a {
  color: #232347;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  border: 1px solid transparent;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
}

.footer-socials a:hover {
  background-color: transparent;
  border-color: var(--secondary-color);
}

@media screen and (max-width: 600px) {
  .footer-menu {
    flex-direction: column;
    gap: 1.25rem;
  }

  .footer-socials {
    margin-bottom: 3.5rem;
  }
}
