.contact-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
}

@media (min-width: 1200px) {
  .contact-container {
    grid-template-columns: 30% 60%;
    gap: 10%;
  }
}

.contact-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
}

.contact-card a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

.contact-card-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
}

form span {
  font-size: 0.8rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: rgb(255 255 255 / 2%);
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
  transition: background-color 0.2s ease;
  box-shadow: 0px 2px 15px rgb(27 27 27 / 8%);
}

input:focus,
textarea:focus {
  background-color: rgb(255 255 255 / 0%);
}

button[type="submit"] {
  padding: 0.5rem 1.25rem;
}

@media screen and (max-width: 1024px) {
  .contact-wrap {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
