.portfolio-container {
  margin-top: 3rem;
}

.portfolio-item {
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  border-radius: 0.5rem;
  background-color: var(--bg-color-secondary);
  box-shadow: 0px 2px 15px rgb(18 66 101 / 8%);
  border: 1px solid transparent;
  transition: border-color 0.2s ease;
}

.portfolio-item:hover {
  border-color: var(--primary-color);
}

.portfolio-item .item-img {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgb(0 0 0 / 8%);
}

.portfolio-item .item-img img {
  padding: 0.25rem;
}

.portfolio-item .item-info {
  padding: 1.5rem 1rem;
}

.portfolio-item .item-info .item-title {
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
  color: var(--heading-color);
}

.portfolio-item .item-info span {
  display: block;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
}

.portfolio-item .item-info small {
  font-weight: 500;
  font-size: 0.775rem;
}

.portfolio-item .item-action {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: var(--primary-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.portfolio-item .item-action a {
  transition: color 0.3s;
  color: #535353;
  margin: 0 10px;
  display: inline-block;
}

.portfolio-item .item-action a:hover {
  color: #000000;
}

.portfolio-item .item-action svg {
  font-size: 18px;
  margin: 0 2px;
  line-height: 0;
}

.portfolio-item:hover .item-action {
  opacity: 1;
}
