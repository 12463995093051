.home {
  text-align: center;
  height: 90vh;
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 0;
}

.home .content {
  position: relative;
}
.home .content .text-primary {
  font-size: 1.25rem;
}

.home-action {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  margin-top: 2.5rem;
}

.home-socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.home-socials a {
  color: var(--link-color);
  padding: 0.25rem;
}

.home-socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background-color: var(--link-color);
}

.home-scroll-down {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: 300;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%) rotate(90deg);
}

.home-scroll-down > a {
  color: var(--link-color);
}

.home-scroll-down::after {
  content: " →";
}

@media (min-width: 992px) {
  .home-socials {
    left: 5rem;
  }

  .home-scroll-down {
    right: 3rem;
  }
}

@media screen and (max-width: 576px) {
  .home-socials,
  .home-scroll-down {
    display: none;
  }
}

.hello-emoji {
  -webkit-animation-name: wave-animation;
  animation-name: wave-animation;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transform-origin: 70% 70%;
  -ms-transform-origin: 70% 70%;
  transform-origin: 70% 70%;
  display: inline-block;
  font-size: 20px;
}

@-webkit-keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
