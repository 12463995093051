.header {
  background-color: var(--bg-navbar);
  padding: 0.5rem 1rem;
  z-index: 2;
  position: fixed;
  right: auto;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  box-shadow: 0px 1px 15px 0px rgb(0 0 0 / 8%);
}

.navbar {
  display: flex;
  gap: 0.8rem;
}

@media (min-width: 1200px) {
  .header {
    padding: 1rem 0.5rem;
    right: 1.5rem;
    left: auto;
    bottom: 50%;
    transform: translateY(50%);
  }
  .navbar {
    flex-direction: column;
  }
}

.navbar .nav-link {
  background-color: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--navbar-icon-color);
  font-size: 1.1rem;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.navbar .nav-link:hover {
  background-color: var(--bg-navbar-active-hover);
}

.navbar .nav-link.active {
  background-color: var(--bg-navbar-active);
  color: var(--primary-color);
}

.nav-item {
  position: relative;
}

.navbar .label {
  color: var(--navbar-icon-color);
  display: none;
  position: absolute;
  background-color: var(--bg-navbar);
  padding: 0.25rem 0.75rem;
  vertical-align: middle;
  font-size: 0.9rem;
  text-transform: capitalize;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  width: 75px;
  text-align: center;
  border-radius: 0.2rem;
  box-shadow: 0px 1px 15px 0px rgb(0 0 0 / 8%);
}

.navbar .label::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid var(--bg-navbar);
  content: "";
}

.navbar .nav-item:hover .label {
  display: block;
}

@media (min-width: 1200px) {
  .navbar .label {
    top: 50%;
    left: -95px;
    transform: translateY(-50%);
  }

  .navbar .label::after {
    left: 100%;
    top: 50%;
    border-right: 5px solid transparent;
    border-left: 5px solid var(--bg-navbar);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transform: translateY(-50%);
  }
}
