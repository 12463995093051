.quotebox {
  border-radius: 1%;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 auto;
}

@media only screen and (min-width: 1200px) {
  .quotebox {
    max-width: 50vw;
  }
}

.quote > h2 {
  line-height: 1.5;
}

.quote-icon {
  color: var(--primary-color);
}

.quotebox h2 {
  margin: 2rem;
  text-align: center;
}

.author {
  margin-left: auto;
  padding-top: 1rem;
  color: var(--light-color);
}
