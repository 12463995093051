:root {
  --body-font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans",
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --heading-font-family: "Source Sans Pro", arial, sans-serif;
  --body-font-size: 1rem;
  --body-font-weight: 400;
  --body-line-height: 1.5;

  --link-color: #3f3f3f;
  --link-hover-color: #111;
  --primary-color: #ffc107;
  --secondary-color: #232347;
  --light-color: #878d92;
  --bg-navbar: rgba(255, 255, 255, 0.5);
  --bg-navbar-active: rgba(0, 0, 0, 0.1);
  --bg-navbar-active-hover: rgba(0, 0, 0, 0.1);
  --bg-color-secondary: #fff;
  --navbar-icon-color: #3f3f3f;
  --body-color: rgb(33, 37, 41);
  --bg-color-gradient: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgb(234, 244, 249) 100%);

  --highlight-bg: #fff3cd;
  --code-color: #d63384;
  --btn-color: rgb(25, 25, 25);
  --btn-hover-color: #fff;
  --btn-hover-bg: #d9a406;
  --btn-hover-border-color: transparent;
}

/* @media (prefers-color-scheme: dark)  */
[data-theme="dark"] {
  --link-color: #e9e9e9;
  --link-hover-color: #fff;
  --primary-color: #ffc107;
  --secondary-color: #eaeaea;
  --light-color: #878d92;
  --body-color: #eaeaea;
  --bg-color: #1f1f38;
  --bg-navbar: rgba(0, 0, 0, 0.5);
  --bg-navbar-active: rgba(255, 255, 255, 0.1);
  --bg-navbar-active-hover: rgba(255, 255, 255, 0.1);
  --bg-color-secondary: #232347;
  --bg-color-gradient: linear-gradient(0deg, rgba(27, 27, 47, 1) 0%, rgba(47, 47, 78, 1) 100%);
  --navbar-icon-color: #ededed;

  --highlight-bg: #fff3cd;
  --code-color: #d63384;
  --btn-hover-color: #fff;
  --btn-hover-bg: #d9a406;
  --btn-hover-border-color: transparent;
}

*,
::after,
::before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  color: var(--body-color);
  background: var(--bg-color-gradient);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font-family);
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 0.875em;
}
mark {
  padding: 0.1875em;
  background-color: var(--highlight-bg);
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.2s ease;
}
a:hover {
  color: var(--link-hover-color);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: var(--font-monospace);
  font-size: 1em;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875em;
  color: var(--code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--body-bg);
  background-color: var(--body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-inner-spin-button {
  height: auto;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}

section {
  position: relative;
  margin-top: 2rem;
  padding: 3.75rem 1.25rem 0 1.25rem;
}

@media (min-width: 992px) {
  section {
    padding: 6.25rem 0 0;
    margin-top: 4rem;
  }
}

@media (min-width: 1200px) {
  section {
    max-width: 75vw;
    margin-inline: auto;
  }
}

.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.75;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--body-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--primary-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--primary-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--btn-color);
}

.btn:hover {
  color: var(--btn-hover-color);
  background-color: var(--btn-hover-bg);
  border-color: var(--btn-hover-border-color);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--btn-color);
  pointer-events: none;
  background-color: var(--primary-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.text-light {
  color: var(--light-color);
}

.text-primary {
  color: var(--primary-color);
}

.text-center {
  text-align: center;
}

.fw-bold {
  font-weight: 700;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.small {
  font-size: 0.9rem;
}

.text-space {
  letter-spacing: 1px;
}

.shadow {
  box-shadow: 0px 3px 20px 0px rgb(0 0 0 / 9%);
}

.grid-2,
.grid-3,
.grid-4 {
  display: grid;
}

@media (min-width: 575px) {
  .grid-2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .grid-3 {
    grid-template-columns: repeat(1, 1fr);
  }
  .grid-4 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 768px) {
  .grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.card-item {
  background-color: var(--bg-color-secondary);
  border: 1px solid transparent;
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  transition: border-color 0.2s ease, background-color 0.2s ease;
}

.card-item:hover {
  border-color: var(--primary-color);
}

img,
figure {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.section-intro {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.section-intro > h2 {
  font-weight: 700;
}

.theme-switch {
  position: fixed;
  right: 5px;
  top: 5px;
  border: none;
  background-color: transparent;
  z-index: 9999;
  display: flex;
  align-items: center;
  height: 35px;
  width: 35px;
  justify-content: center;
  cursor: pointer;
}

.to-top-progress {
  display: none !important;
}

@media (min-width: 992px) {
  .to-top-progress {
    display: grid !important;
  }
}

.loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: typing 1s linear infinite alternate;
  animation: typing 1s linear infinite alternate;
  margin: 46px auto;
  position: relative;
  left: 0;
}
@keyframes typing {
  0% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 2), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2), 24px 0px 0px 0px rgba(255, 255, 255, 1);
  }
}

@-webkit-keyframes typing {
  0% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 2), 24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2), 24px 0px 0px 0px rgba(255, 255, 255, 1);
  }
}
